<template>
	<h1>Change Password</h1>
	<p id="intro">You can use the form below to change your Pennytel password. Your new password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.</p>
	<div v-if="changingPassword " class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<form class="data-form" @submit.prevent="changePassword">
			<div>
				<label for="current-password">Current Password</label>
				<input type="password" id="current-password" v-model="currentPassword" @input="resetStatusMessages" />
			</div>
			<div>
				<label for="new-password">New Password</label>
				<input type="password" id="new-password" v-model="newPassword" @input="resetStatusMessages" />
			</div>
			<div>
				<label for="confirm-password">Confirm Password</label>
				<input type="password" id="confirm-password" v-model="confirmPassword" @input="resetStatusMessages" />
			</div>
			<div class="button-wrapper">
				<button type="submit">Save Password</button>
			</div>
		</form>
		<div class="error">{{errorMessage}}</div>
		<div v-if="passwordChanged" class="success-message">Password Changed Successfully</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentPassword: '',
				newPassword: '',
				confirmPassword: '',
				errorMessage: null,
				passwordChanged: false,
				changingPassword: false
			}
		},
		methods: {
			async changePassword() { // Performs the API request to change the password of the authenticated user.
				if(this.validateForm()) { // Only attempt to perform the API request if the form has been filled out correctly.
					try {
						// Replace the change password form with a loading indicator.
						this.changingPassword = true;
						
						// Perform the API request to change the password of the authenticated user.
						const data = {current_password: this.currentPassword, new_password: this.newPassword};
						await this.HTTP.put('user/password', data);
						
						// If the user's password was updated successfully, display the success message and reset the form.
						this.passwordChanged = true;
						this.resetForm();
					} catch(error) { // If there was an error changing the user's password, display an error message below the change password form.
						this.errorMessage = (error.response && error.response.status == 400) ? error.response.data.error : 'An error has occurred.'; // A 400 status code indicates a validation error, so we should display the error returned by the API. Any other error is an internal error, so we should just display a generic error message.
					} finally { // Regardless of whether the API request was successfull, hide the loading indicator and re-display the form.
						this.changingPassword = false;
					}
				}
			},
			validateForm() { // Validates that the change password form has been filled out correctly.
				// Ensure that all fields in the change password form have been filled in.
				const requiredFields = {'current password': this.currentPassword, 'new password': this.newPassword, 'password confirmation': this.confirmPassword}
				for(const field in requiredFields) {
					const value = requiredFields[field];
					if(value == '') {
						this.errorMessage = `The ${field} is required.`;
						return false;
					}
				}
				
				// Ensure that the New Password field matches the Confirm Password field.
				if(this.newPassword != this.confirmPassword) {
					this.errorMessage = "New Password and Confirm Password fields don't match.";
					return false;
				}
				
				return true;
			},
			resetForm() { // Removes the values from all fields in the change password form.
				this.currentPassword = '';
				this.newPassword = '';
				this.confirmPassword = '';
			},
			resetStatusMessages() { // Removes any success and error messages display in the change password form.
				this.passwordChanged = false;
				this.errorMessage = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	#intro, .error {
		text-align:center;
	}
</style>